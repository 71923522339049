import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import './VendorStatus.css';
import { useVendorContext } from './VendorContext';
import { useNavigate } from 'react-router-dom';

const VendorPage = () => {
  const navigate = useNavigate();
  const { vendorNo, vendorStatus } = useVendorContext();
  const [vendorDetails, setVendorDetails] = useState([]);
  const destination = "myhome";
  const [loading, setLoading]=useState(false);

  console.log("vendorNo, vendorStatus from VendorContext is: ", vendorNo, vendorStatus);
  useEffect(() => {
    const username = 'SRIAABAP';
    const password = 'Patil@2234';
    const credentials = `${username}:${password}`;
    const base64Credentials = btoa(credentials);
    setLoading(true);
    const fetchData = async () => {
      try {
        const apiUrl = `http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor?sap-client=200&AUTO_USER=${vendorNo}`;
        //const apiUrl = 'http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor?sap-client=200&AUTO_USER=0000000351';
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Authorization': 'Basic ' + base64Credentials,
          },
        });

        if (!response.ok) {
          setLoading(false);
          throw new Error('Network response was not ok');
        }
        //console.log("Response : ", response);
        const apiData = await response.json();
        setLoading(false);
        //console.log(apiData);
        setVendorDetails([apiData.VENDOR_NO, apiData.VEND_STATUS, apiData.VEND_DATE]);
        //console.log("ApiData is: ", apiData);
        //console.log(vendorDetails);
      } catch (error) {
        console.log(error);
        return alert('Error fetching data:', error);
      }
    };
    fetchData();

  }, [vendorNo]);

  const handleViewData = () => {
    navigate(`/Registration3/${destination}`);
  }

  const formatDateToDMY = (isoDate) => {
    const date = new Date(isoDate);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-based
    const year = date.getFullYear();

    // Ensure leading zeros for day and month if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  return (
    <div>
      {loading && (
        <div className='overlay'>
          <div className='spinner-container'>
            <div className="loading-spinner"></div>
            <span className='loading-message'>Loading...</span>
          </div>
        </div>
      )}
      <div className='vendor-status-container'>
        <div className='vendor-details-table'>
          {/*<h5>Vendor Reference Details</h5>
      <p>Vendor Number: {vendorDetails[0]}</p>
      <p>Vendor Status: {vendorDetails[1]}</p>
      <p>Vendor Initiated Time : {vendorDetails[2]}</p>
      <button className='btn btn-primary'>View Details</button>*/}
          <table className='table-alignment'>
            <thead>
              <tr>
                <th colSpan="4"><h5 className='text-center reference-details-heading'>Vendor Reference Details</h5></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2" className='table-content-label'>Vendor Number</td>
                <td colSpan="2" className='table-content-values'>{vendorDetails[0]}</td>
              </tr>
              <tr>
                <td colSpan="2" className='table-content-label'>Vendor Status</td>
                <td colSpan="2" className='table-content-values'>{vendorDetails[1]}</td>
              </tr>
              <tr>
                <td colSpan="2" className='table-content-label'>Requested Date</td>
                <td colSpan="2" className='table-content-values'>{formatDateToDMY(vendorDetails[2])}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='mt-3'>
          <button className='btn btn-primary custom-view-details-button' onClick={handleViewData}>View Details</button>
        </div>
      </div>
    </div>
  );
};

export default VendorPage;